import YingHaoBlackTitle from "@/components/YingHaoBlackTitle.vue";
import Button1 from "@/components/button1.vue";
import "@/styles/detailPage.scss";

export const myMixinDetailPage = {
  components: {
    YingHaoBlackTitle,
    Button1
  },
  data() {
    return {};
  }
};
