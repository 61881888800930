<script>
import { myMixinDetailPage } from "@/mixins/myMixinDetailPage";

import { ImagePreview } from "vant";
export default {
  mixins: [myMixinDetailPage],
  props: {
    content: {
      type: Object,
      default: () => null
    }
  },
  render(h) {
    if (!this.content) return null;
    if (!this.content.type || !this.content.content) return null;
    const { type, content } = this.content;
    switch (type) {
      case "header":
        return h(
          "div",
          {
            class: "m-t-18 p-b-5"
          },
          [
            h("Button1", {
              props: {
                title: content
              }
            })
          ]
        );
      case "title":
        return h("h1", content);
      case "paragraph":
      case "p":
        return h("p", content);
      case "author":
        return h("p", { class: "author m-t-0" }, [content]);
      case "img":
      case "image":
        return h("img", {
          attrs: { src: content, class: "img_w_100 b-r-6 o-h" },
          on: {
            click: () => {
              ImagePreview({
                images: [content],
                closeable: true,
                showIndex: false
              });
            }
          }
        });
      case "html":
        return h("div", {
          domProps: {
            innerHTML: content
          }
        });
      default:
        return null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
