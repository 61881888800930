<template>
  <div class="viewMethodsPage" v-if="detail">
    <div class="topImg" v-if="detail.topImg">
      <img class="img_w_100" :src="detail.topImg" alt="" />
      <div class="bl"></div>
    </div>
    <div class="p-h-24 contentArea">
      <div class="top m-b-18">
        <YingHaoBlackTitle :title="detail.title"></YingHaoBlackTitle>
      </div>
      <div class="content" v-if="detail.children">
        <RenderDetailItem
          v-for="(item, index) in detail.children"
          :content="item"
          :key="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { myMixinDetailPage } from "@/mixins/myMixinDetailPage";
import RenderDetailItem from "./RenderDetailItem.vue";
export default {
  components: {
    RenderDetailItem
  },
  props: {
    detail: {
      type: Object,
      default: () => null
    }
  },
  mixins: [myMixinDetailPage]
};
</script>
