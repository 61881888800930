<template>
  <span class="btn p-v-8 p-r-10">
    <span class="text">{{ title }}</span>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  border-width: 0 30px 0 15px;
  border-style: solid;
  border-image-source: url(~@/images/viewing-methods/btnbg.jpg);
  border-image-slice: 0 41 0 22 fill;
  .text {
    text-align: left;
    font-size: 15px;
    color: #ffffff;
  }
}
</style>
