<template>
  <span class="title p-h-5"> {{ title }} </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  padding: 0 10px;
  // background-image: url("~@/images/page-top-yinhao1.png"),
  //   url("~@/images/page-top-yinhao2.png");
  // background-repeat: no-repeat;
  // background-position: top left, bottom right;
  // background-size: 7px auto, 7px auto;
  position: relative;
  font-weight: bold;
  &::after,
  &::before {
    display: block;
    content: "";
    width: 8px;
    height: 10px;
    position: absolute;
  }
  &::before {
    top: 0px;
    left: 0;
    border-left: 2px solid black;
    border-top: 2px solid black;
  }
  &::after {
    bottom: 0px;
    right: 0;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
  }
}
</style>
