<template>
  <div class="viewMethodsPage">
    <DetailPure :detail="detail" />
    <NavigatorBar />
  </div>
</template>

<script>
import DetailPure from "@/components/detail/detailPure_p.vue";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["specialAddress", "articles"]),
    detail() {
      const index = this.articles.findIndex(
        (article) => article.id === "ancient-tree-viewing-methods"
      );
      if (index > -1) {
        return this.articles[index];
      }
      return null;
    }
  },
  components: {
    DetailPure
  }
};
</script>

<style lang="scss" scoped>
.viewMethodsPage {
  color: #000;
}
.top {
  align-items: center;
}
.content {
  text-align: left;
  font-size: 12px;
  .author {
    text-align: right;
    color: #777777;
    font-size: 9px;
  }
}
.btn-more {
  width: 53px;
  height: 18px;
  font-size: 9px;
  color: #ffffff;
  background-color: #48c360;
  border-radius: 18px;
}
</style>
